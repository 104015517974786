<template>
    <modal ref="modalDetallePago" titulo="Editar CEDIS" tamano="modal-lg" no-aceptar cerrar no-cancelar>
        <div class="row mx-0">
            <div class="col-6 f-500 f-14 text-general">
                Cedis
            </div>
            <div class="col-4 f-500 f-14 text-general">
                Tiempo auto-cancelación (min)
            </div>
            <div class="col-1 f-500 f-14 text-general">
                Estado
            </div>
        </div>
        <div v-for="(c,key) in cedis" :key="key" class="row my-2">
            <div class="col-6">
                <div class="border br-5 bg-whitesmoke text-general pl-2 f-14 py-1">
                    {{ c.cedis }}
                </div>
                <p class="f-12 text-general">Cuenta activa: {{ c.cuenta_principal? c.cuenta_principal :'No registra' }}</p>
            </div>
            <div class="col-4">
                <el-input v-model="c.auto_abandono" size="small" max-length="3" placeholder="minutos" class="w-100" @change="cambioMinutos(c)" />
            </div>
            <div class="col-1">
                <el-checkbox v-model="c.estado" :true-label="1" :false-label="0" class="check-green" @change="cambioEstado(c)" />
            </div>
            <div class="col-1">
                <el-tooltip placement="bottom" content="Eliminar" effect="light">
                    <i class="text-general-red f-17 icon-cancel-circled-outline cr-pointer" @click="eliminarCedis(c.id)" />
                </el-tooltip>
            </div>
        </div>
    </modal>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/pagos"
export default {
    data(){
        return {
            value:'',
            estado:1,
            cedis:[],
            id_cuenta:null
        }
    },

    methods: {
        toggle(id_cuenta){
            this.id_cuenta = id_cuenta
            this.listarCedis()
            this.$refs.modalDetallePago.toggle();
        },
        async listarCedis(){
            try {
                const {data} = await Pagos.listarCedis(this.id_cuenta)

                for (const c of data.cedis){
                    c.estado =  c.estado ? c.estado : 0
                }
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        async getDetalle(){
            try {

                const {data} = await Pagos.detallePago(this.basicos.id)

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioMinutos(item){
            if(!isNaN(item.auto_abandono)){
                const form = {
                    id_cedis:item.id_cedis,
                    id_cuenta:item.id_cuenta,
                    auto_abandono:item.auto_abandono
                }
                const {data} = await Pagos.cambioTiempoCancelacionCedis(form)
                this.notificacion('','Tiempo de auto-cancelación actualizado','success')

            }else{
                this.notificacion('Advertencia','Por favor ingrese un numero valido','warning')
            }
        },
        async cambioEstado(item){
            try {
                const form = {
                    estado:item.estado,
                    id_cedis:item.id_cedis,
                    id_cuenta:item.id_cuenta
                }
                const {data} = await Pagos.cambioEstadoCedis(form)
                this.notificacion('','Estado actualizado','success')
                this.listarCedis()

            } catch (e){
                this.error_catch(e)

            }
        },
        async eliminarCedis(id){
            try {

                const {data} = await Pagos.eliminarCedisCuenta(id)
                this.notificacion('','Cedis eliminado de la cuenta','success')
                this.listarCedis()

            } catch (e){
                this.error_catch(e)

            }
        }
    }
}
</script>
